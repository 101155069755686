//#region Imports and Types

import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { easings, useSpring } from '@react-spring/web';

import { vec3 } from '../data types/mystarpath_types';
import { ThreeElements } from '@react-three/fiber';

export interface SpaceshipRef {

    isInHyperspace: boolean; // Variable to let the outside world know if the spaceship is in  the "hyperspace" zoom animation
    setupHyperspace: () => void; // Method to setup the spaceship for the "hyperspace" zoom animation
    animateLookAtPosition: (targetLookAt: vec3) => void;
};

type SpaceshipProps = {
    startingCameraPosition: vec3; // We're going to place our spaceship behind the starting position of our camera
    shipFacingDirection: vec3;
    onLoad: () => void;
}

//#endregion

// Preload our spaceship model for the experience
const spaceshipModel = useGLTF.preload('/assets/models/spaceship/model.gltf');

const Spaceship = forwardRef<SpaceshipRef,SpaceshipProps>(({startingCameraPosition, shipFacingDirection, onLoad}, ref) => {

    console.log("(Spaceship) - Rendering!");

    const shipRef = useRef<ThreeElements["primitive"]>();
    const isInHyperspace = useRef(false);
    const [isOnStandby, setOnStandby] = useState<boolean>(null); // Variable to control when the spaceship is idly floating in space
    const [shipDirection, setShipDirection] = useState<vec3>(shipFacingDirection);
    const spaceshipModel = useGLTF('/assets/models/spaceship/model.gltf'); //TODO - Extend the GLTF Loader, to handle errors!

    const startingDistanceBehindCamera = 1.5;
    const startingDistanceBelowCamera = 1.5;

    const standbyFloatInterval = 0.001; // The distance that the spaceship will idly float up and down
    const standbyFloatTime = 3000; // The time (in ms) it takes for the spaceship to float up and down

    // #region Animations

    const [animateLookAtPositionController, lookAtControllerAPI] = useSpring(() => ({

        // Start the animation from the ship's current rotation
        from: {ship_Z_rotation : 0,
               ship_Y_rotation: 0
        }, 

        to: {},

        config: {
            duration: 3000,
            precision: 0.0001, 
            easing: easings.linear //Decelerates to zero velocity
        },

        onStart: ({value}) => {
            console.log("-------------------");
            console.log("(Spaceship) - Starting the rotation animation of the spaceship!");
        },

        onChange: ({value}) => {   
            // Update the spaceships's rotation values
            shipRef.current.rotation.y = value.ship_Y_rotation;          
            shipRef.current.rotation.z = value.ship_Z_rotation;          
        },

        onRest: () => {
            console.log("(Spaceship) - Finished animating lookAt position of the spaceship!");
            console.log("-------------------");
        }

    }));

    const [floatAnimation, floatAnimationAPI] = useSpring(() => ({

        from: {ship_Y_position: -standbyFloatInterval},

        to: {ship_Y_position: standbyFloatInterval}, // The target position will be set when the animation starts

        loop: {reverse: true},

        config: {
            duration: standbyFloatTime,
            precision: 0.0001, 
            easing: easings.easeInQuad // Accelerates from zero velocity
        },

        onChange: ({value}) => {   
            // Update the spaceships's position values
            shipRef.current.position.y += value.ship_Y_position;          
        },

    }));

    // #endregion

    // #region useEffects

    // Effect for when the spaceship first renders
    useEffect(() => {
        if (shipRef.current) 
        {
            console.log("(Spaceship) - Changing our spaceship's positioning and lookAt direction!");

            // Calculate the starting position of the spaceship, behind the camera
            const startingPosition : vec3 = [startingCameraPosition[0], 
                                             startingCameraPosition[1] - startingDistanceBelowCamera, 
                                             startingCameraPosition[2] - startingDistanceBehindCamera];

            // Set the position of the spaceship
            shipRef.current.position.set(startingPosition[0], startingPosition[1], startingPosition[2]);

            setOnStandby(true); // begin the spaceship's floating animation

            // Notify our parent that the spaceship has been loaded!
            onLoad();

        }
      }, [shipRef]);

    // Effect for when the spaceship is on standby, (triggers whenever the isOnStandy state variable changes)
    useEffect(() => {

        if (isOnStandby) {
            console.log("(Spaceship) - Triggering the floating animation of the spaceship!");
            floatAnimationAPI.start({ to: {ship_Y_position: standbyFloatInterval}});
        }
        else if(isOnStandby === false) {
            console.log("(Spaceship) - Triggering the end floating animation of the spaceship!");
            floatAnimationAPI.stop();
        }

    }, [isOnStandby]);

      // #endregion

    // #region Methods for external callers

    useImperativeHandle(ref, () => ({

        isInHyperspace: isInHyperspace.current,

        setupHyperspace: () => {
            console.log("(Spaceship) - Preparing spaceship to enter hyperspace !");

            // Stop the floating animation
            setOnStandby(false);
        },

        // Initial animation, when the user first enters into the website!
        animateLookAtPosition: (targetLookAt: vec3) => { 

            console.log("(Spaceship) - Animating the spaceship to look at a new position!");

            // Rotate the ship's z-axis 30 degrees to the left
            lookAtControllerAPI.start({to: {ship_Z_rotation: -0.5,
                                            ship_Y_rotation: 0.5
            }}); 
        }

    }));

        // #endregion
    
    return (

        <primitive ref={shipRef}
                   object={spaceshipModel.scene}
                   scale={1.0} />
    )
});

export default Spaceship;
