import PlanetType from "./PlanetType";

// #region Animations

export enum AnimationType  {

    INTRO_SCREEN_ANIMATION_BEGIN,
    NAVIGATION_WINDOW_CLOSED,
    PRE_BURST_ENDED,
    BURST,
    BEGIN_HYPERSPACE_ANIMATION,
    EXIT_HYPERSPACE_ANIMATION,
    INTRO_SCREEN_ANIMATION_ENDED,
    HUD_TEXT_ENDED,

};

export interface AnimationProps {

    INTRO_SCREEN_ANIMATION_BEGIN?: {
        startingLookPos: vec3;
    }

    INTRO_SCREEN_ANIMATION_ENDED?: {
        lookAtPos: vec3;
    };
}

export interface PulseAnimationProps {

    blur: string,
    brightness: string,
}

// #endregion

// #region Audio

export enum AudioFile {

    HUD_TEXT_BEEP = '/assets/sounds/Interface_Typing.mp3',
    HYPERSPACE_ANIMATION_SOUND = '/assets/sounds/hyperspace-animation-sound.mp3',
    SUBMIT_SOUND = '/assets/sounds/submit-sound.mp3',
}

export type AudioCacheType = {
    
    audioElement: HTMLAudioElement;
    componentsInUse: string[];
    onPlayCallbacks: (() => void)[];
}

export interface AudioControllerContextType {
    
    preloadAudio: (src: AudioFile, caller: string, onPlay?: () => void) => Promise<HTMLAudioElement>;
    playAudio: (src: AudioFile) => void;
}

// #endregion

// #region Data Types and Functions

export type vec3 = [number, number, number];
export function vec3_add(a: vec3, b: vec3): vec3
{
    return [
        a[0] + b[0], 
        a[1] + b[1], 
        a[2] + b[2]
    ];
}

// #endregion

// #region Tailwind Styles

export enum ButtonStyles {
    
    // py-2: padding on the y-axis of 2 units
    // bg-transparent: background color is transparent
    // text-neon-purple: text color is neon purple
    // select-none: text selection is disabled
    // focus:outline-none: outline is removed when the button is focused
    bare = "py-2 bg-transparent text-neon-purple select-none focus:outline-none",
};

// #endregion

// #region Components

export type PlanetContextType = {

    selectedPlanet: PlanetType; //The ID of the planet that is currently selected in the scene
    zoomedPlanet: PlanetType; // The ID of the planet that is currently zoomed in on, in the scene
};

export type OK_Button = {
    
        buttonStyle: ButtonStyles;
        OnSubmit?: () => void;
        text?: React.ReactNode;
        additionalClassStyling?: string;
};

// #endregion