// #region Imports

import * as THREE from 'three';

import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { easings, useSpring } from '@react-spring/web';

import { Howl } from 'howler';

import { AnimationType, AudioFile, vec3 } from '../data types/mystarpath_types';

// #endregion

/* #region Animation config types

easeInQuad: Accelerates from zero velocity.
easeOutQuad: Decelerates to zero velocity.
easeInOutQuad: Accelerates until halfway, then decelerates.
easeInCubic: Accelerates from zero velocity more aggressively.
easeOutCubic: Decelerates to zero velocity more aggressively.
easeInOutCubic: Accelerates until halfway, then decelerates more aggressively.
easeInQuart: Accelerates from zero velocity even more aggressively.
easeOutQuart: Decelerates to zero velocity even more aggressively.
easeInOutQuart: Accelerates until halfway, then decelerates even more aggressively.
easeInQuint: Accelerates from zero velocity most aggressively.
easeOutQuint: Decelerates to zero velocity most aggressively.
easeInOutQuint: Accelerates until halfway, then decelerates most aggressively.
linear: Constant speed.

*/

// #region Interfaces

export function CameraProperties() {
    return {
        baseFOV: 75,
        minFOV: 45,
        maxFOV: 90,
        baseScreenWidth: 1920,
        getMultiplierPerFOV: (fov: number): number =>  {
            // MmltiplierPerFOV was found by taking the difference between the baseFOV and minFOV, figuring out what multiplier distance looked
            // good on a mobile device (approx. 2.33), and using that value to interpolate how much the multplier should change per FOV.
            // e.g [x1, y1] = [baseFOV, 2.33], 
            // [x2, y2] = [minFOV, 1],

            // y = mx + b
            const m = -0.04433;
            const b = 4.32485;

            return m * fov + b;     
       }
    }
};

export function Calculate_CameraFOV(screenWidth: number, baseScreenWidth: number, baseFOV: number, minFOV: number, maxFOV: number) {

    const calculated_fov = baseFOV * (screenWidth / baseScreenWidth); // Calculate the new FOV, based on the screen width

    console.log("Screen Width: ", screenWidth);
    console.log("Calculated FOV: ", calculated_fov);

    return Math.max(minFOV, Math.min(calculated_fov, maxFOV)); // Clamp the FOV to the min and max values
}

// Interface for outside callers, to change the camera's target position
export interface CameraControllerRef { 

    startInitialAnimation: (startingLookPos: vec3) => void;
    start_preBurstAnimation: () => void;
    exitHyperdrive: (targetLookAt: vec3, targetPosition: vec3) => void;
    moveToPlanet: (targetPosition: vec3, lookAtPosition?: vec3) => void;
}

type CameraControllerProps = { // Props for the CameraController component

    camera: THREE.PerspectiveCamera; // Reference to the camera object
    onAnimationEnd: (AnimationType) => void; // Callback function to notify when the animation has ended
};

// #endregion

const CameraController = React.memo(forwardRef<CameraControllerRef, CameraControllerProps>(({camera, onAnimationEnd}, ref) => 
{
    console.log("Camera Controller - Rendering!");

    // #region Use Hooks

    const {size} = useThree(); //Get reference to our scene's camera

    const [lookAtPosition, setLookAtPosition] = useState<vec3>(null); // State variable of where the camera is looking at
    const previousLookAtPosition = useRef<vec3>(null); // Reference to the previous lookAt position

    const FOV_Multiplier = useRef(1.0); // Position multiplier for the camera's field of view

    const [cameraAnimationPosition, setCameraAnimationPosition] = useState<vec3>(null); // State variable of our camera's position

    // #endregion

    // #region Audio

    const burstVolume = 0.20; // Volume for the burst and pre-burst animations

    const submitSound = useRef(new Howl({ // Sound for the Navigate button
        src: [AudioFile.HYPERSPACE_ANIMATION_SOUND],
        preload: true,
        volume: burstVolume
    }));

    // #endregion

    // #region Animations

    const introAnimationDuration = 4000; // in ms.
    const preBurstAnimationDuration = 2000; 
    const burstAnimationDuration = 200;

    const preBurstAnimationThrustRetraction = 0.5; // The distance the camera will thrust forward, during the pre-burst animation
    const preBurstAnimationZoomIn = 1.5; // The distance the camera will thrust upwards, during the pre-burst animation

    const burstAnimationThrustForward = 6.0; // The distance the camera will thrust forward, during the burst animation
    const burstAnimationThrustUpwards = 1.5; // The distance the camera will thrust upwards, during the burst animation

    const [preBurstAnimation, preBurstAnimationAPI] = useSpring(() => ({

        from: { y_position: camera.position.y, //We'll be animating the y-position of the camera, to zoom-in slowly. 
                z_position: camera.position.z}, //We'll be animating the z-position of the camera, to back up slowly. 

        to: {}, // This will be filled in by the animation's caller.  

        config: {
            duration: preBurstAnimationDuration,
            precision: 0.0001, 
            easing: easings.easeInQuad
        },

        onStart: ({value}) => {
            console.log("-------------------");

            console.log("(CameraController) - Playing audio for hyperspace animation, at the beginning of the pre-burst animation sequence!");
            submitSound.current.play(); 
        },

        onChange: ({value}) => {   
            setCameraAnimationPosition([camera.position.x, value.y_position, value.z_position]); // Update the camera's y & z-positions
        },
        
        onRest: () => {
            console.log("(CameraController) - Pre-burst intro animation came to a rest !");

            onAnimationEnd(AnimationType.PRE_BURST_ENDED); // Notify the parent component that the animation has ended

            console.log("(CameraController) - Triggering the Burst Animation to begin!");

            BurstAnimationAPI.start({from: {y_position: camera.position.y, z_position: camera.position.z},
                                     to: {y_position: camera.position.y + burstAnimationThrustUpwards, z_position: camera.position.z - burstAnimationThrustForward}});

            console.log("-------------------");
        }

    }));

    const [BurstAnimation, BurstAnimationAPI] = useSpring(() => ({

        from: {y_position: camera.position.y,
               z_position: camera.position.z,
        }, 

        to: {}, // This will be filled in by the animation's caller.   

        config: {
            duration: burstAnimationDuration,
            precision: 0.0001, 
            easing: easings.easeInQuart // Shoot forward, with lots of acceleration!
        },

        onChange: ({value}) => {   
            setCameraAnimationPosition([camera.position.x, value.y_position, value.z_position]); // Update the camera's y & z-positions
        },

    }));

    const [IntroAnimationController, IntroAnimationAPI] = useSpring(() => ({

        from: { y_position: camera.position.y,
                z_position: camera.position.z,
        }, 

        to: {}, // This will be filled in by the animation's caller.   

        config: {
            duration: introAnimationDuration,
            precision: 0.0001, 
            easing: easings.easeInOutCubic
        },

        onStart: ({value}) => {
            console.log("-------------------");
            console.log("(CameraController) - Camera intro animation starting!");
            console.log(IntroAnimationController.y_position.animation.to);
            console.log("(CameraController) - Moving to position: ", [camera.position.x, IntroAnimationController.y_position.animation.to,  IntroAnimationController.z_position.animation.to]);
        },

        onChange: ({value}) => {   
            setCameraAnimationPosition([camera.position.x, value.y_position, value.z_position]); // Update the camera's y & z-positions
        },

        onRest: () => {
            console.log("(CameraController) - Camera intro animation resolved!");

            onAnimationEnd(AnimationType.INTRO_SCREEN_ANIMATION_ENDED); // Notify the parent component that the animation has ended
        }

    }));

    const [MovePlanetAnimationController, MovePlanetAnimationAPI] = useSpring(() => ({

        from: { lookAt: previousLookAtPosition.current,  // Where was the camera previously looking at? By the time the planet animation starts, this property should be filled out.
                position: [camera.position.x, camera.position.y, camera.position.z] // This is the physical position of the camera, initially
        }, 

        to: {}, // This will be filled out when the animation starts, by the caller.  

        pause: true, // Start the animation paused, so we can control when it starts    

        config: key => { //let's have different configurations, based on what "key" we're animating.

            let animationTime; // time of the animation, in ms.

            if (key ==  "lookAt") 
                { animationTime = 2750 }
            else 
                { animationTime = 3000}

            return {
                duration: animationTime, 
                precision: 0.0001,
                mass: 1,
                tension: 280, //higher tension for faster acceleration
                friction: 50, //friction to slow down, at the end       
                easing: easings.easeOutCubic 
            }               
        },

        onStart: ({value}) => {
            console.log("-------------------");
            console.log("(CameraController) - Move Planet animation starting!");
        },

        onChange: ({value}) => {
            setLookAtPosition(value.lookAt);
            setCameraAnimationPosition(value.position);
        },

        onResolve: () => {
            console.log("(CameraController) - Move Planet animation resolved!");
            console.log("-------------------");
        }

    }));

    // #endregion

    // #region Calling Methods - methods to expose to external callers

    useImperativeHandle(ref, () => ({

        // Initial animation, when the user first enters into the website!
        startInitialAnimation: (startingLookPos: vec3) => { 

            console.log("(CameraController) - Triggering the Intro Animation to start!");

            setLookAtPosition(startingLookPos); // Set the initial lookAt position

            IntroAnimationAPI.start({to: { y_position: camera.position.y + (1.5 * FOV_Multiplier.current),
                                           z_position: camera.position.z - (6.0 * FOV_Multiplier.current),
            }});
        },

        // Animation to trigger the camera's "burst" movement into hyperspace!
        start_preBurstAnimation: () => {

            console.log("(CameraController) - Triggering the pre-Burst Animation to begin!");

            preBurstAnimationAPI.start({from: {y_position: camera.position.y, z_position: camera.position.z}, 
                                        to: {y_position: camera.position.y - preBurstAnimationZoomIn , z_position: camera.position.z + preBurstAnimationThrustRetraction}});                  
            console.log("-------------------");
        },

        // Animation to move the camera slightly in front of a planet
        moveToPlanet: (targetPosition: vec3, lookAtPosition?: vec3) => {

            console.log("(CameraController) - Triggering the Move Planet Animation to start!"); 

            if (camera.position.distanceTo(new THREE.Vector3(...targetPosition)) < 0.1) // If the camera is already at the target position (within a certain tolerance), don't animate it again!
            {
                console.log("(CameraController) - Camera is already at the target position...terminating animation.");
                return;
            }

            const positionToLookAt = lookAtPosition ?? targetPosition; // If no lookAtPosition is provided, look at the center of the new "planet"
            setLookAtPosition(positionToLookAt); 

            MovePlanetAnimationAPI.start({lookAt: lookAtPosition, // Where do we want to look at?
                                          position: [targetPosition[0] + 0.70, targetPosition[1] + 0.50, targetPosition[2]]}); // Where we will be physically moving the camera to); 
        },

        // Animation to rotate the camera around the spaceship, and focus in on the default selected planet (in the distance)
        exitHyperdrive: (targetLookAt: vec3, targetPosition: vec3) => {

            console.log("(CameraController) - Triggering exitHyperdrive to start!"); 

            setLookAtPosition(targetLookAt); 

            MovePlanetAnimationAPI.start({lookAt: targetLookAt, // Where do we want to look at?
                                          position: targetPosition}); // Where we will be physically moving the camera to); 
        },

    }));

    // #endregion

    // #region React Effects

    // Effect to adjust the camera's view, based on the size of the window
    useEffect(() => {

        console.log("(CameraController) - Adjusting the camera's view!");
        const camera_properties = CameraProperties();

        camera.aspect = size.width / size.height; // Adjust the camera's aspect ratio
        console.log("Camera aspect ratio: ", camera.aspect);

        const camera_fov = Calculate_CameraFOV(size.width, camera_properties.baseScreenWidth, camera_properties.baseFOV, 
                                               camera_properties.minFOV, camera_properties.maxFOV); // Adjust the camera's field of view
        
        console.log("Camera FOV: ", camera_fov);
        camera.fov = camera_fov; // Set the camera's field of view

        // Update our FOV multiplier
        // For each FOV value below the base FOV, we multiply those values by the multiplierPerFOV value
        const fov_multiplier = camera_properties.getMultiplierPerFOV(camera_fov); // Smallest value is a multiplier of 1, largest value is a multiplier of 2.33.

        console.log("FOV Multiplier: ", fov_multiplier);

        // Get the difference between our previous multiplier, and the new multiplier
        const multiplier_difference = fov_multiplier - FOV_Multiplier.current;

        console.log("Multiplier Difference: ", multiplier_difference);

        FOV_Multiplier.current = fov_multiplier; // Update the FOV multiplier

        // If the multiplier_difference is positive, we need to zoom out. 
        // If it's negative, we need to zoom in.

        const camera_y_pos = camera.position.y + (multiplier_difference * 1.5); // Update the camera's y-position
        const camera_z_pos = camera.position.z - (multiplier_difference * 6.0); // Update the camera's z-position

        setCameraAnimationPosition([camera.position.x, camera_y_pos, camera_z_pos]); // Update the camera's y & z-positions    

        camera.updateProjectionMatrix(); // Update the camera's projection matrix

    }, [size]);

    useEffect(() => {
        
        if (cameraAnimationPosition == null)
            return;

        camera.position.set(cameraAnimationPosition[0], cameraAnimationPosition[1], cameraAnimationPosition[2]); 
        camera.lookAt(lookAtPosition[0], lookAtPosition[1], lookAtPosition[2]); 

    }, [cameraAnimationPosition]);

    // #endregion

    return null; //TODO - Export custom slider zoom, for the camera zooming in and out! 

}));

export default CameraController;
