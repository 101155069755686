import React from 'react';
import { ButtonStyles } from '../data types/mystarpath_types';

interface ButtonProps {

  id?: string;
  buttonStyle: ButtonStyles;
  onClick?: () => void;
  children?: React.ReactNode;
  additionalClassStyling?: string;
}

export default function InterfaceButton({ id, buttonStyle, onClick, children, additionalClassStyling } : ButtonProps)
{

  return (
    
    <button id={id}
            onClick={onClick}
            className={buttonStyle + additionalClassStyling}>

      {children}

    </button>
  );

};
