//#region Imports and Interfaces

import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { animated, easings, useChain, useSpring, useSpringRef } from '@react-spring/web';

import { Howl } from 'howler';

import { AnimationType, AudioFile, ButtonStyles, PulseAnimationProps } from '../data types/mystarpath_types';
import InterfaceButton from './InterfaceButton';
import useWindowSize from './WindowInterface';

interface IntroInterfaceProps {

    trigger: boolean;
    onAnimationEnd?: (AnimationType) => void;
    text: string;
};

// #endregion

const IntroductionInterface : React.FC<IntroInterfaceProps> = ({trigger, text, onAnimationEnd}) => {

    // #region Setup Variables

    const [animationTrigger, setAnimationTrigger] = useState(trigger);
    const [showComponent, setShowComponent] = useState(trigger);

    const heightAnimationRef = useSpringRef();
    const widthAnimationRef = useSpringRef();

    //Use hook to get the window size
    const { width, height } = useWindowSize();

    const interfaceWidth = getInterfaceViewportWidth(width); // Width of the interface in vw (viewport width)
    const interfaceHeight = getInterfaceViewportHeight(width); // Height of the interface in vh (viewport height)

    // #endregion

    // #region Sound Variables

    const submitVolume = 0.05; // Volume for the Navigate button
    const typingVolume = 0.15;

    const submitSound = useRef(new Howl({ // Sound for the Navigate button
        src: [AudioFile.SUBMIT_SOUND],
        preload: true,
        volume: submitVolume
    }));

    const typingSound = useRef(new Howl({ // Sound for the typing effect
        src: [AudioFile.HUD_TEXT_BEEP],
        preload: true,
        volume: typingVolume
    }));

    // #endregion

    // #region Animation Variables

    const [pulseAnimationParameters, setPulseAnimationParameters] = useState<PulseAnimationProps>({
        blur: 'blur(0px)',
        brightness: 'brightness(0)'
    }); // The current blur value of the text

    const expansionAnimationTime_H = 150; 
    const expansionAnimationTime_W = 300; 

    const pulseExpansionTime = 2000; // The time (in ms) it takes for the blur to expand outwardly
    const maxBlur = 12; // The maximum blur value that the text will reach
    const maxBrightness = 1.75; // The maximum brightness value that the interface's border will reach while animating
    const minBrightness = 0.45; // The minimum brightness value that the interface's border will reach while animating
    const maxBlurBrightness = 1.25; // The maximum brightness value that the interface's border will reach while animating

    const heightAnimation = useSpring({
        ref: heightAnimationRef,
        height: animationTrigger ? interfaceHeight : '0vh',
        from: { height: '0vh' },
        config: { duration: expansionAnimationTime_H },
        
        onRest: () => {
            if(animationTrigger == false) { // Hide the component after the reverse animation is done
                setShowComponent(false);

                // Let listeners know that the animation has ended
                if(onAnimationEnd) { 
                    onAnimationEnd(AnimationType.NAVIGATION_WINDOW_CLOSED) ;
                }
        }}
    });

    const widthAnimation = useSpring({
        ref: widthAnimationRef,
        width: animationTrigger ? interfaceWidth : '0vw',
        from: { width: '0vw' },
        config: { duration: expansionAnimationTime_W },

        onStart: () => {
            // Play the audio file when the text is first reveleased
            if(animationTrigger) {
                typingSound.current.play();
            }
        }
    });

    const [pulseAnimation, pulseAnimationAPI] = useSpring(() => ({

        from: { blur: 0,
                brightness: minBrightness,
         },
        
        to: {}, // This will be filled in by the animation's caller

        config: { duration: pulseExpansionTime,
                  easing: easings.easeInQuad,
                  precision: 0.0001,
        },

        onChange: ({value}) => {
            setPulseAnimationParameters({blur: `blur(${value.blur}px)`, brightness: `brightness(${value.brightness})`});
        },

    }));             

    // Use the useChain hook to chain the animations together. 
    // [0, 0.5] means that the first animation will start at 0 seconds, and the second animation will start at 0.5 seconds.
    useChain(animationTrigger ? [heightAnimationRef, widthAnimationRef] : [widthAnimationRef, heightAnimationRef], 
        [0, animationTrigger ?  0.25 : 0.5]);

    // #endregion

    // #region Effects

    // Effect to preload the audio files for the component
    useEffect(() => {

        console.log("(IntroductionInterface) - Mounting!");

        return () => {
            console.log("(IntroductionInterface) - Unmounting! Cleaning up resources!");

            // Stop all of our animations
            heightAnimationRef.stop();
            widthAnimationRef.stop();
            pulseAnimationAPI.stop();
        }

    }, []);

    // Effect to show or hide the component based on the trigger
    useEffect(() => {

        if(trigger) {
            // Start the pulse animation
            pulseAnimationAPI.start({blur: maxBlur,
                                     brightness: maxBrightness,
                                     loop: {reverse: true}});
        }

        setShowComponent(trigger);
        setAnimationTrigger(trigger);

    }, [trigger]);

    // #endregion

    // #region Tailwind Styles

    const backdropStyle = 'absolute z-10 mt-12 ' + // Absolutely positioned container, with a flexbox column layout
                          'left-1/2 transform -translate-x-1/2 ' + //Positions the left edge of the element at 50% of the container's width, and translates the element left by 50% of its own width, effectively centering it horizontally.
                          'bg-transparent '; //backdrop-blur-2px

    const borderStyle = 'absolute inset-0 pointer-events-none ' + // Take up space in the entire parent container (backdropStyle)
                        'border rounded-md border-neon-purple ';  // Add a border and background color

    const containerStyle = 'flex flex-col justify-between ' + // Absolutely positioned container, with a flexbox column layout
                           'bg-purple-base bg-opacity-10 ' + 
                           'border-2 rounded-lg border-neon-purple ';

    const titleStyle = 'flex flex-row justify-center ' + // Flexbox row layout with center justification, within the interface container
                       'mt-2 md:mt-4 h-1/2 ' + // Margin on the top and on both sides, and take up half of the container's height (h-1/2)
                       'text-lg md:text-xl lg:text-3xl xl:text-3xl ' + 
                       'text-faded-neon font-nidus-regular tracking-wide ' +
                       'select-none'; // Disable text selection

    const buttonContainerStyle = 'flex flex-row justify-center '; // Flexbox row layout with center justification

    const additionalButtonStyle = 'w-24 mb-0.5 2xl:mb-2 mx-3 ' + // Add margins on the bottom (mb-4)
                                  'text-md md:text-lg lg:text-xl 2xl:text-2xl font-liberation-mono ' + // Font size and text style
                                  'hover:text-selection-color hover:font-bold'; // Hover effects

    // #endregion

    // #region JSX Return

    return (
        <>
        { showComponent &&

            <div id='background-backdrop'
                 className={backdropStyle}>

                <animated.div id='background-color-pulsing-container'
                              className={borderStyle}
                              style={{filter: pulseAnimationParameters.blur}} />

                <animated.div id='interface-container' 
                              className={containerStyle}
                              style={{...widthAnimation,
                                      ...heightAnimation,
                                      filter: pulseAnimationParameters.brightness,
                                      whiteSpace: 'nowrap', 
                                      overflow: 'hidden'}}>

                    <div id='interface-title' 
                         className={titleStyle}>
                        {text}            
                    </div>

                    <div id='button-container' className={buttonContainerStyle}>

                            <InterfaceButton id='go-back-button'
                                             buttonStyle={ButtonStyles.bare} 
                                             additionalClassStyling={additionalButtonStyle}>

                                [ Go Back ]

                            </InterfaceButton>

                            <InterfaceButton id='navigation-button'
                                             buttonStyle={ButtonStyles.bare}
                                             additionalClassStyling={additionalButtonStyle}
                                             onClick={() => {

                                                console.log("(IntroductionInterface) - Signalling to listeners that the user is navigating into the application!");

                                                // Play the onSubmit sound
                                                submitSound.current.play();

                                                setAnimationTrigger(false);

                                             }}>

                                [ Navigate ]

                            </InterfaceButton>
                    </div>

                </animated.div>

            </div>
        }
        </>
    )

    // #endregion
};

export default React.memo(IntroductionInterface);

// #region Helper Functions

function getInterfaceViewportWidth(width: number)
{
    // Return various uv sizes, based on the width being given
    if(width <= 700) {
        return '80vw'
    }
    else if (width < 1400 ) {
        return '48vw';
    }
    else {
        return '35vw'; //smallest size
    }
}

function getInterfaceViewportHeight(width: number)
{
    // Return various uv sizes, based on the width being given
    if(width < 700) {
        return '16vh';
    }
    else if (width <= 1160) {
        return '18vw';
    }
    else {
        return '22vh';
    }
}

function debugViewportSize(width: number)
{
    // Return the "sm, md, lg, xl, 2xl" breakpoints based on the width being given
    if(width < 640) {
        return "base";
    }
    else if(width <= 768) {
        return "sm";
    }
    else if(width < 1024) {
        return "md";
    }
    else if(width < 1280) {
        return "lg";
    }
    else {
        return "xl";
    }
}

// #endregion