import ReactDOM from 'react-dom/client'
import React from 'react'
import { Howler } from 'howler'

import ApplicationManager from './ApplicationManager'
import './style.css'

// Disable autoUnlock to prevent audio from automatically unlocking playing on mobile devices
Howler.autoUnlock = false;

const root = ReactDOM.createRoot(document.querySelector('#root'))

// Enable Strictmode for debugging purposes, only. Strict Mode is ignored once the application is built 
root.render(   

    <div>
        <ApplicationManager />   
    </div>
)
