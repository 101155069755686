// #region Imports and Interfaces

import * as THREE from 'three';
import React, { useImperativeHandle, forwardRef, useMemo, useRef, useState } from "react"
import { useFrame } from '@react-three/fiber';
import { useSpring } from '@react-spring/web';
import { AnimationType, vec3 } from '../data types/mystarpath_types';
import starMap from '/assets/backgrounds/starfield_template_background.png';

export interface SpacefieldRef { 

    startHyperspaceAnimation: () => void;
}

type SpacefieldProps = { // Props for the Spacefield component

    centerPoint: vec3; // The center point of the starfield
    onAnimationEnd: (AnimationType) => void;
};

// #endregion

const Spacefield = forwardRef<SpacefieldRef, SpacefieldProps>(({centerPoint, onAnimationEnd}, ref) => 
{
    // #region Setup Variables

    const starSize = 0.7;
    const forbiddenRange = 50;
    const coordinateRange = 300;
    const starsToGenerate = 2500;
    const animationDuration = 10000000; //3000; // The duration of the zoom animation

    const acceleration_factor = useRef(0.015); // The acceleration factor of the star's "zoom animation"
    const deceleration_factor = 0.993; // The deceleration factor of the star's "zoom animation"

    // #region State Variables

    const [accelerationAnimationTrigger, setAccelerationTrigger] = useState(false); // State variable to trigger the zoom animation
    const [decelerationAnimationTrigger, setDecelerationTrigger] = useState(false); // State variable to trigger the zoom animation to slow down


    const [starGeometry] = useState<THREE.BufferGeometry>(() => { //Run initializing function only once, when the component is first rendered
        return generateStarfield(starsToGenerate, coordinateRange, centerPoint, forbiddenRange);
    });
    
    const starMaterial = useMemo(() => { 
        console.log("(Spacefield) - Loading star texture!");
        return new THREE.PointsMaterial({color: 0xffffff, // TODO - Handle error case for TextureLoader onError condition! 
                                         size: starSize, 
                                         alphaTest: 0.5,
                                         transparent: true,
                                         sizeAttenuation: true,
                                         map: new THREE.TextureLoader().load(starMap)})}, [])

    // Initialize the velocity matrix, with an Array of "starsToGenerate" number of Vector3 objects
    // the second argument to useMemo is the dependency array, which will trigger a re-render if the value changes
    const starVelocities : vec3[] = useMemo(() => {

        return generateVelocityValues(starsToGenerate)

    }, [starsToGenerate]);

    useFrame(() => {

        if(!(accelerationAnimationTrigger || decelerationAnimationTrigger)) // The animations have ended (or haven't begun)
        {
            return;
        }

        const animationHasEnded = accelerateVertexValues(starGeometry, starVelocities, coordinateRange, 
                                                         acceleration_factor, decelerationAnimationTrigger, deceleration_factor);

        if(animationHasEnded) // If the animation has ended, trigger the onAnimationEnd function
        {
            setDecelerationTrigger(false);
            onAnimationEnd(AnimationType.EXIT_HYPERSPACE_ANIMATION);
        }

        // Signal to update the position attribute of the starGeometry
        starGeometry.attributes.position.needsUpdate = true;
    });

    // useEffect is used to trigger the onAnimationEnd function, when the deceleration animation ends

    // #endregion

    // #endregion   
    
    // #region Methods for outside callers

    useImperativeHandle(ref, () => ({

        // Initial animation, when the user first enters into the website!
        startHyperspaceAnimation: () => { 

            console.log("(Spacefield) - Starting the zoom animation");  

            setAccelerationTrigger(true); // Trigger the animation

            if(!accelerationAnimationTrigger) { // If the animation is not already running, start it!

                const timeout = setTimeout(() => { //  setTimeout to trigger the end of the animation

                    setAccelerationTrigger(false);
                    setDecelerationTrigger(true);

                }, animationDuration); 

                return () => {
                    clearTimeout(timeout); // Cleanup function, to clear the timeout.
                };      
            }
        }
    }));


    // #endregion

    return (     

        <points geometry={starGeometry} material={starMaterial} />
    )
});  

export default Spacefield;

// #region Helper Methods

function generateStarfield(numberOfStars: number, size: number, center: vec3, exclusionRange: number) : THREE.BufferGeometry
{
    console.log("(Spacefield) - Generating starfield");

    const starGeometry = new THREE.BufferGeometry();
    const starVertices: Array<number> = [];

    for (let i = 0; i < numberOfStars; i++) { // Create 'numberOfStars' stars around the center point!

        let starCoordinate = new THREE.Vector3((Math.random() * size * 2) - size, // The positions of the stars are random, between -300 and 300
                                               (Math.random() * size * 2) - size, 
                                               (Math.random() * size * 2) - size);

        starCoordinate.add(new THREE.Vector3(center[0], center[1], center[2])); // Move the star, relative to the center of the starfield

        const distanceBetweenVectors = new THREE.Vector3(center[0], center[1], center[2]).distanceTo(starCoordinate); // Calculate the distance between the star and the center

        if(distanceBetweenVectors <= exclusionRange) 
        { 
            // skip to the next iteration, if the star is too close to the sphere of the forbidden range
            i--;
            continue;
        }

        starVertices.push(starCoordinate.x, starCoordinate.y, starCoordinate.z);
    }

    starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));

    return starGeometry;
}

/**
 * Generates an array of velocity values for stars.
 * 
 * @param numberOfStars - The number of stars to generate velocity values for.
 * @returns An array of Vector3 objects representing the velocities of the stars.
 */
function generateVelocityValues(numberOfStars: number) : Array<vec3>
{
    console.log("(Spacefield) - Generating star velocities");
    const velocities = new Array<vec3>(numberOfStars).fill([0,0,0]);
    
    return velocities;
}

 function accelerateVertexValues(geometryMatrix : THREE.BufferGeometry, velocityMatrix: vec3[], rangeToReset : number, 
                                 acceleration_factor: React.MutableRefObject<number>, decelerate : boolean = true, deceleration_factor = 1.0) : boolean
{   
    let signalAnimationToEnd = false;

    if(decelerate) // If we are decelerating, slow the acceleration factor down
    {
        acceleration_factor.current *= deceleration_factor; 

        if(acceleration_factor.current <= 0.001) // If the acceleration factor is too small, end the animation
        {
            signalAnimationToEnd = true;
        }
    }

    // For each "star" in our starVelocities matrix, add the velocity by the acceleration factor
    velocityMatrix.forEach((local_velocity, index) => {

        local_velocity[2] += acceleration_factor.current; // Increase the star's velocity

        const star_z_position = geometryMatrix.attributes.position.getZ(index); // Get the y-position of the star

        // Get the same "star" in our geometryMatrix, and update its y-position
        if(star_z_position - local_velocity[2] < -rangeToReset) 
        {
            // If the star has gone out of the range, reset its velocity and position, to the beginning!
            local_velocity[2] = 0;
            geometryMatrix.attributes.position.setZ(index, rangeToReset);
        }
        else
        {
            geometryMatrix.attributes.position.setZ(index, star_z_position - local_velocity[2]); // Update the star's position
        }  
    }); 

    return signalAnimationToEnd;

}

// #endregion