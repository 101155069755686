// #region Imports

import { useSpring, animated } from '@react-spring/web'
import { Canvas } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import React, { createContext, useState, useRef } from 'react';

import * as THREE from 'three';

import './style.css'
import Spaceship from './library/Spaceship.tsx';
import Planet from './content/Planet.tsx';
import ContentWindow from './content/ContentWindow.tsx';
import Spacefield from './library/Spacefield.tsx';
import { SpacefieldRef } from './library/Spacefield.tsx';
import { SpaceshipRef } from './library/Spaceship.tsx';
import IntroductionInterface from './library/IntroductionInterface.tsx';
import CameraController, { CameraProperties, Calculate_CameraFOV, CameraControllerRef } from './library/CameraController.tsx';
import { AnimationProps, AnimationType, ButtonStyles, PlanetContextType, vec3, vec3_add } from './data types/mystarpath_types.tsx';
import PlanetType from './data types/PlanetType.tsx';

// #endregion

// Export the context of the planet that is currently selected/zoomed in, for other components to use.
export const PlanetAppContext = createContext<PlanetContextType>(null); // The context of the planet that is currently selected/zoomed in

export default function ApplicationManager() {

  console.log("(ApplicationManager) - Starting the Application Manager!");

  //#region Setup

  //#region State Variables

  const [displayScene, setDisplayScene] = useState(false);   // Variables to notify if/when the current scene has been loaded
  const [pageContext, setPageContext] = useState<PlanetContextType>(null); // The context of the planet that is currently selected/zoomed in
  const spacefieldRef = useRef<SpacefieldRef>(null); // Reference to the animated text component 
  const spaceshipRef = useRef<SpaceshipRef>(null); // Reference to the spaceship component

  const [isNavigationOpen, setIsNavigationOpen] = useState<boolean>(true); // Variable to track if the navigation window is open

  //#endregion

  //#region User's Planet Data
  const userPlanets: PlanetType[] = [
    {
      planetID: "0",
      planetPosition: [0,0,0], 
      planetTexture: './assets/earth/day.webp', 
      planetColor: '#093044', },
  
    {
      planetID: "1",
      planetPosition: [-1,0,3], 
      planetTexture: './assets/earth/day.webp', 
      planetColor: '#ff0000', 
  
    }];
  //#endregion
  
  //#region Initial Variables

  const initialSelectedPlanet : PlanetType = (pageContext?.selectedPlanet) ?? userPlanets[0]; // Universe's planet that is initially selected
  const galaxyPosition : vec3 = vec3_add(initialSelectedPlanet.planetPosition, [-3.6, 10.52, -2.2015]); //The "first tier" level position of the camera, hovering above the selected planet
  const initialStartingPosition : vec3 = [galaxyPosition[0] * 3, 32, galaxyPosition[2] * 3]; // Initial starting position of the camera

  // Initial starting position of where the camera is looking at
  // It's looking to the "right" of the selected planet, above it, and at the same perceptual "depth" as the selected planet
  const initialCameraLookPosition : vec3 = [initialStartingPosition[0], initialStartingPosition[1], initialSelectedPlanet.planetPosition[2]]; 

  // #endregion

  //#region Camera stuff
  const cameraControllerRef = useRef<CameraControllerRef>(null); // Reference to the camera controller component

  const [camera] = useState<THREE.PerspectiveCamera>(() => {
      
    console.log("(ApplicationManager) - Creating the initial camera!");

    const cameraSettings = CameraProperties();

    // Clamo the initial camera's FOV, between the min and max values
    const fov = Calculate_CameraFOV(window.innerWidth, cameraSettings.baseScreenWidth, 
                                    cameraSettings.baseFOV, cameraSettings.minFOV, cameraSettings.maxFOV);

    let cam =  new THREE.PerspectiveCamera(fov, window.innerWidth / window.innerHeight, 0.1, 1000);
    cam.position.set(...initialStartingPosition);
    cam.lookAt(...initialCameraLookPosition);

    cam.updateProjectionMatrix();

    return cam;
})
  // #endregion

  // #endregion

  // #region Event Handlers

  // Handler method to tell the CameraController to move to the new target position
  const changeTargetPosition = (position : vec3, lookAtPosition?: vec3) => {
    if(cameraControllerRef.current)
    {
      cameraControllerRef.current.moveToPlanet(position, lookAtPosition);
    }
    else
    {
      //TODO - Unexpected error case, handle here!!!
    }
  };

  // Handler method for when a planet is clicked
  // The planet has already done a validation check that the click should work, so we just have to 
  // pass the planet's metrics to the camera controller 
  const handlePlanetClick = (planetID: string) => {

    console.log("(ApplicationManager) - Planet clicked!");
    const localPlanet = userPlanets.find(planet => planet.planetID === planetID); // get the planet that was clicked

    console.log("(ApplicationManager) - Planet to zoom in on: ", localPlanet);

    setPageContext({selectedPlanet: null, zoomedPlanet: localPlanet}); // Set the context of the selected planet

    changeTargetPosition(localPlanet.planetPosition); // Move the camera to the new planet's position
  };

  // Handler method to return the camera back to the galaxy view, from a zoomed-in planet
  const handleGalaxyView = () => {  

    console.log("(ApplicationManager) - Going back to galaxy view!");

    // TODO - Change the default selected planet logic in real application!
    const localPlanet = (pageContext?.selectedPlanet) ?? userPlanets[0];
    setPageContext({selectedPlanet: localPlanet, zoomedPlanet: null}); // Reset the context of the zoomed-in planet

    changeTargetPosition(galaxyPosition, localPlanet.planetPosition); 
  };

  // #endregion

  // #region Animations

  const [websiteBackground] = useSpring(() => ({
      from: { opacity: 0},
      to: { opacity: 1},
      config: { duration: 2500 },   
      onRest: () => { console.log("(ApplicationMananger) - Initial fade animation finished!");
                      setDisplayScene(true); 
      }

  }));

  //#endregion

  //#region JSX Return
                    
  return (
    <>
      <animated.div className='fixed top-0 left-0 w-full h-full bg-black'
                    style={websiteBackground}>


          { isNavigationOpen && <IntroductionInterface trigger={displayScene}
                                 text="NAVIGATE TO AARON'S UNIVERSE?"
                                 onAnimationEnd={(animation) => { handleIntroductionInterfaceAnimations(animation, cameraControllerRef, spaceshipRef);
                                                                  setIsNavigationOpen(false); }
                                }/>
          }

          <Canvas camera={camera}>

              <CameraController ref={cameraControllerRef}
                                camera={camera}
                                onAnimationEnd={(animation) => handleSpacefieldAnimations(animation, spacefieldRef)} />

              <Spacefield ref={spacefieldRef}
                          centerPoint={initialStartingPosition}
                          onAnimationEnd={(animation) => {
                            handleCameraControllerAnimations(animation, cameraControllerRef);
                            handleSpaceshipAnimations(animation, spaceshipRef); }} />

              <Spaceship ref={spaceshipRef}
                         startingCameraPosition={initialStartingPosition}
                         shipFacingDirection={initialCameraLookPosition}
                         onLoad={() => handleCameraControllerAnimations(AnimationType.INTRO_SCREEN_ANIMATION_BEGIN, 
                                                                        cameraControllerRef, { INTRO_SCREEN_ANIMATION_BEGIN: { 
                                                                                                startingLookPos: initialCameraLookPosition }} )} />
          </Canvas> 

      </animated.div>

    </>
  );

  //#endregion

};

function handleCameraControllerAnimations(animation: AnimationType, cameraref: React.MutableRefObject<CameraControllerRef>, animationProps?: AnimationProps)
{
  if(!cameraref.current)
  {
    console.log("(ApplicationManager) - Unexpected error case, CameraController reference is null in animation handler!");
    return;
  }

  switch(animation)
  {
    case AnimationType.INTRO_SCREEN_ANIMATION_BEGIN:
      cameraref.current.startInitialAnimation(animationProps.INTRO_SCREEN_ANIMATION_BEGIN.startingLookPos);
      break;

    case AnimationType.EXIT_HYPERSPACE_ANIMATION:
      break;

    default:
      console.log("(ApplicationManager) - Unexpected CameraController animation type received! :", animation);
  }
}

function handleSpacefieldAnimations(animation: AnimationType, spaceobject: React.MutableRefObject<SpacefieldRef>)
{
  if(!spaceobject.current)
  {
    console.log("(ApplicationManager) - Unexpected error case, Spacefield reference is null in animation handler!");
    return;
  }

  switch(animation)
  {
    case AnimationType.PRE_BURST_ENDED:
      // Trigger the start of the Spacefield's Burst animation
      spaceobject.current.startHyperspaceAnimation();
      break;

    // animations to ignore
    case AnimationType.INTRO_SCREEN_ANIMATION_ENDED:
      break;

    default:
      console.log("(ApplicationManager) - Unexpected Spacefield animation type received! :", animation);
  }
}

function handleSpaceshipAnimations(animation: AnimationType, shipref: React.MutableRefObject<SpaceshipRef>, animationProps?: AnimationProps)
{
  if(!shipref.current)
  {
    console.log("(ApplicationManager) - Unexpected error case, Spaceship reference is null in animation handler!");
    return;
  }

  switch(animation)
  {
    // case AnimationType.INTRO_SCREEN_ANIMATION_ENDED:
    //   // Trigger the start of the Spaceship's Burst animation
    //   shipref.current.animateLookAtPosition(animationProps.INTRO_SCREEN_ANIMATION_ENDED.lookAtPos);
    //   break;

    case AnimationType.EXIT_HYPERSPACE_ANIMATION:
      break;

    default:
      console.log("(ApplicationManager) - Unexpected Spaceship animation type received! :", animation);
  }
}

function handleIntroductionInterfaceAnimations(animation: AnimationType, cameraref: React.MutableRefObject<CameraControllerRef>,  shipref: React.MutableRefObject<SpaceshipRef>)
{
  switch(animation)
  {
    // Start the burst animation, and prep the spaceship to shift from a "floating" animation to a "tilting" animation
    case AnimationType.NAVIGATION_WINDOW_CLOSED:

      cameraref.current.start_preBurstAnimation();
      shipref.current.setupHyperspace();
      
      break;

    default:
      console.log("(ApplicationManager) - Unexpected Introduction Interface animation type received! :", animation);
  }
}

function handleBeginNavigation(cameraref: React.MutableRefObject<CameraControllerRef>)
{
  console.log("(ApplicationManager) - User has clicked the Navigation button!");
}

/* Code to add back in! 

  <TheUniverse>  

    <PlanetAppContext.Provider value={pageContext}>

      { userPlanets.map((planet) => ( // Map through the user's planets and render them

        <Planet key={planet.planetID} 
                planetID={planet.planetID}
                planetPosition={planet.planetPosition} 
                planetTexture={planet.planetTexture} 
                planetColor={planet.planetColor}
                onClick={handlePlanetClick}
                goBack={handleGalaxyView} />
      ))}                    

    </PlanetAppContext.Provider>
                  
</TheUniverse>   


*/